import React from "react";
import { Paragraph, TextContainer, Ul } from "../../lib/src";
import { DocPageMeta, HeaderSection, ContentSection, Header } from "../docs";
import { DoDont } from "../docs/docs-do-dont";

export const Meta: DocPageMeta = {
	category: "Writing",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Tone of voice"
			subTitle="The way we write is an expression of who we are. It’s a reflection of our brand and the values we uphold as a company. Our voice should be consistent throughout the customer journey. Tone is how we adjust our voice to different situations. This guide covers both."
		/>
		<ContentSection>
			<TextContainer article>
				<Header.H2>Using the Siteimprove voice</Header.H2>
				<Paragraph>
					Siteimprove’s voice is: empowering, personable, insightful, clear (EPIC). These qualities
					define the content on our website – and should also be apparent in every interaction users
					have with the platform.{" "}
				</Paragraph>
				<Paragraph>
					Even the smallest piece of microcopy can influence the way customers use and perceive our
					products. It’s therefore important to keep the Siteimprove voice in mind no matter what
					you’re writing content for.
				</Paragraph>
				<Header.H3>Empowering</Header.H3>
				<Paragraph>
					We want users to feel confident and in control. Our job is to deliver the information and
					guidance they need to work effectively in the platform.
				</Paragraph>
				<Ul
					items={[
						"Be helpful, but not overbearing.",
						"Make sure the next step is always clear.",
						"Recognize that not every user is an expert.",
						"Stay focused on user goals and keep our ego in check.",
					]}
				/>
				<Header.H3>Personable</Header.H3>
				<Paragraph>
					We keep things professional, yet friendly. We write conversationally, as if speaking
					face-to-face with the user. We offer help when needed, but never impose it.
				</Paragraph>
				<Ul
					items={[
						"Be empathetic and encouraging, without being patronizing.",
						"Use “you” when referring to the user.",
						"Always use inclusive language.",
						"Never scold users for not living up to our standards.",
					]}
				/>
				<Header.H3>Insightful</Header.H3>
				<Paragraph>
					We’re digital experts who recognize that our users are busy people. We’re selective about
					the information we put in front of them, and always relate it back to user goals.
				</Paragraph>
				<Ul
					items={[
						"Be precise, but not excessive when explaining things.",
						"All killer – no filler.",
						"Avoid ambiguity and uncertain language (“possibly”, “seems”, “quite”).",
						"Get to the point quickly, and never lose sight of the bigger picture.",
					]}
				/>
				<Header.H3>Clear</Header.H3>
				<Paragraph>
					Our solutions speak for themselves — we don’t need to use fancy words and phrasing in
					order to impress users.
				</Paragraph>
				<Ul
					items={[
						"Write for scanning.",
						"Use simple, clear language that anyone can understand.",
						"Minimize the use of jargon and made-up terms.",
						"Consider our international audience (avoid idioms, regional expressions, and terms that might not translate well).",
					]}
				/>
				<Header.H2>Setting the tone</Header.H2>
				<Paragraph>
					While our voice stays the same, we adapt our tone to reflect what the user is doing and
					how they might be feeling. For most common tasks, our tone is unobtrusive and part of a
					seamless experience.
				</Paragraph>
				<Paragraph>This section features tone guidelines for the following UX scenarios:</Paragraph>
				<Ul
					items={[
						/* eslint-disable react/jsx-key */
						<a href="#standard-ux-microcopy">Standard UX microcopy</a>,
						<a href="#issues-and-issue-descriptions">Issues and issue descriptions</a>,
						<a href="#errors-and-warnings">Errors and warnings</a>,
						<a href="#progress-and-congratulations">Progress and congratulations</a>,
						<a href="#encouraging-action">Encouraging action</a>,
						<a href="#help-content-for-complex-workflows">Help content for complex workflows</a>,
						<a href="#introducing-changes-and-new-features">
							Introducing changes and new features
						</a>,
						/* eslint-enable react/jsx-key */
					]}
				/>
				<Header.H3>Standard UX microcopy</Header.H3>
				<Header.H4>Possible emotional states</Header.H4>
				<Paragraph>All the emotions.</Paragraph>
				<Header.H4>Do's and Dont's</Header.H4>
				<DoDont.Layout>
					<DoDont.Do>Be human.</DoDont.Do>
					<DoDont.Dont>Overcomplicate things with technical language and jargon.</DoDont.Dont>
					<DoDont.Do>Be plain and direct.</DoDont.Do>
					<DoDont.Dont>Be wordy or grand.</DoDont.Dont>
					<DoDont.Do>
						Provide clear information for users to carry out tasks and reach their goals.
					</DoDont.Do>
					<DoDont.Dont>
						Overload the interface with information that isn’t relevant to the task.
					</DoDont.Dont>
					<DoDont.Do>
						Use simple explanations so even the least-technical user can understand what to do (or
						know who to delegate to).
					</DoDont.Do>
					<DoDont.Dont>
						Make up terms for things if there’s already an industry term for it.
					</DoDont.Dont>
				</DoDont.Layout>
				<Header.H3>Issues and issue descriptions</Header.H3>
				<Header.H4>Possible emotional states</Header.H4>
				<Ul items={["Curious", "Concerned", "Uncertain", <>"Just trying to do their job"</>]} />
				<Header.H4>Do's and Dont's</Header.H4>
				<DoDont.Layout>
					<DoDont.Do>Be authoritative.</DoDont.Do>
					<DoDont.Dont>Be authoritarian or demanding.</DoDont.Dont>
					<DoDont.Do>Use short and scannable sentences. Divide longer content up.</DoDont.Do>
					<DoDont.Dont>Use jargon without good reason.</DoDont.Dont>
					<DoDont.Do>
						Explain industry terms and acronyms the first time you use them on a page.
					</DoDont.Do>
					<DoDont.Dont>
						Be vague — not every user understands the implication of an issue.
					</DoDont.Dont>
					<DoDont.Do>Be concise — always choose clarity over brevity.</DoDont.Do>
					<DoDont.Dont>
						Go into too much detail in the platform - if it’s a really complex topic, link out to
						the help centre or other relevant resources.
					</DoDont.Dont>
					<DoDont.Do>Focus on why resolving the issue is important and how to do it.</DoDont.Do>
					<DoDont.Dont>Just say it's something we've detected.</DoDont.Dont>
				</DoDont.Layout>
				<Header.H3>Errors and warnings</Header.H3>
				<Header.H4>Possible emotional states</Header.H4>
				<Ul items={["Confused", "Frustrated", "Anxious"]} />
				<Header.H4>Do's and Dont's</Header.H4>
				<DoDont.Layout>
					<DoDont.Do>Clearly explain how to solve the problem. </DoDont.Do>
					<DoDont.Dont>Make things sound scary or dramatic — “fatal error”.</DoDont.Dont>
					<DoDont.Do>Apologize when the error is our fault.</DoDont.Do>
					<DoDont.Dont>Blame or shame the user if it's their fault.</DoDont.Dont>
					<DoDont.Do>Be brief — one or two lines is usually enough.</DoDont.Do>
					<DoDont.Dont>Attempt to explain system errors, unless it will help Support.</DoDont.Dont>
					<DoDont.Do>
						Use a neutral tone - be mindful the user could be stressed or confused.
					</DoDont.Do>
					<DoDont.Dont>
						Be silly or playful — especially if it means the user has lost work “Oops!”
					</DoDont.Dont>
				</DoDont.Layout>
				<Header.H3>Progress and congratulations</Header.H3>
				<Header.H4>Possible emotional states</Header.H4>
				<Paragraph>
					Our solutions speak for themselves — we don’t need to use fancy words and phrasing in
					order to impress users.
				</Paragraph>
				<Ul items={["Confident", "Satisfied", "Motivated"]} />
				<Header.H4>Do's and Dont's</Header.H4>
				<DoDont.Layout>
					<DoDont.Do>Thank users for doing “extra” work, like providing feedback.</DoDont.Do>
					<DoDont.Dont>
						Over-congratulate &mdash; this can come across as patronizing (“You edited your settings
						- great job!”).
					</DoDont.Dont>
					<DoDont.Do>Be clear that an action has been completed.</DoDont.Do>
					<DoDont.Dont>Take credit for users' work &mdash; “we did it!”.</DoDont.Dont>
					<DoDont.Do>
						Be specific about what has happened &mdash; “widget added to dashboard”.
					</DoDont.Do>
					<DoDont.Dont>Be ambiguous about something happening &mdash; “Added”.</DoDont.Dont>
				</DoDont.Layout>
				<Header.H3>Encouraging action</Header.H3>
				<Header.H4>Possible emotional states</Header.H4>
				<Paragraph>
					Our solutions speak for themselves — we don’t need to use fancy words and phrasing in
					order to impress users.
				</Paragraph>
				<Ul items={["Curious", "Uncertain", "Purposeful"]} />
				<Header.H4>Do's and Dont's</Header.H4>
				<DoDont.Layout>
					<DoDont.Do>Use verb-led commands (e.g. “Do this” or “Create this”).</DoDont.Do>
					<DoDont.Dont>
						Overload the interface with information that isn’t relevant to the task at hand.
					</DoDont.Dont>
					<DoDont.Do>Be positive and inspire users to try new things.</DoDont.Do>
					<DoDont.Dont>Use too many call-to-actions on one page.</DoDont.Dont>
					<DoDont.Do>
						Explain the benefits of doing something and include a clear next step.
					</DoDont.Do>
					<DoDont.Dont>Leave the user hanging.</DoDont.Dont>
					<DoDont.Do>
						Limit the number of tooltips in the interface - there is such thing as too helpful.
					</DoDont.Do>
					<DoDont.Dont>
						Use content or walkthroughs to explains calls to action - this undermines the design.
					</DoDont.Dont>
				</DoDont.Layout>
				<Header.H3>Help content for complex workflows</Header.H3>
				<Header.H4>Possible emotional states</Header.H4>
				<Ul items={["Uncertain", "Confused", "Frustrated"]} />
				<Header.H4>Do's and Dont's</Header.H4>
				<DoDont.Layout>
					<DoDont.Do>Be clear and to the point.</DoDont.Do>
					<DoDont.Dont>Present too much information at once.</DoDont.Dont>
					<DoDont.Do>For longer content, use headings to help users scan for answers.</DoDont.Do>
					<DoDont.Dont>
						Hide mission-critical information in tooltips, or in disappearing modals or Learn
						components.
					</DoDont.Dont>
					<DoDont.Do>Use Pendo tutorials for step-by-step instructions.</DoDont.Do>
					<DoDont.Dont>Use if-statements or ask rhetorical questions.</DoDont.Dont>
					<DoDont.Do>Link out to the Help Center if it’s an especially tricky subject.</DoDont.Do>
					<DoDont.Dont>Be silly or patronizing.</DoDont.Dont>
					<DoDont.Do>Be consistent with the terms used in the interface.</DoDont.Do>
					<DoDont.Dont>Assume the user is technically skilled.</DoDont.Dont>
				</DoDont.Layout>
				<Header.H3>Introducing changes and new features</Header.H3>
				<Header.H4>Possible emotional states</Header.H4>
				<Ul items={["Unaware", "Excited", <>"Just trying to get on with things"</>]} />
				<Header.H4>Do's and Dont's</Header.H4>
				<DoDont.Layout>
					<DoDont.Do>Focus on how it will help users achieve their goals.</DoDont.Do>
					<DoDont.Dont>Go overboard with clichés and marketing spiel.</DoDont.Dont>
					<DoDont.Do>Present a clear next step.</DoDont.Do>
					<DoDont.Dont>
						Focus too heavily on how its built, or be too self-congratulatory (“we’ve designed this
						brilliant new thing”).
					</DoDont.Dont>
					<DoDont.Do>Be positive and enthusiastic about the benefits.</DoDont.Do>
					<DoDont.Dont>
						Say something is easy or simple to do - this a subjective judgment.
					</DoDont.Dont>
					<DoDont.Do>Keep it succinct - be respectful of users' time.</DoDont.Do>
					<DoDont.Dont>
						Force users to engage - we may be preventing them from completing their goals.
					</DoDont.Dont>
				</DoDont.Layout>
			</TextContainer>
		</ContentSection>
	</>
);
