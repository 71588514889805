import React from "react";
import {
	cn,
	Icon,
	IconError,
	IconSuccess,
	InlineText,
	Paragraph,
	TextContainer,
} from "../../lib/src";
import {
	ColorStatusNegativeDark,
	ColorStatusPositiveDark,
} from "../../lib/src/design-tokens/variables";
import * as scss from "./docs-do-dont.scss";

export type DoDontProps = {
	/** The guideline text */
	children?: React.ReactChild;
	/** Affects colors, icon, and label */
	variant: "doThis" | "dontDoThis";
	/** Adds a box on top containing an example image  */
	exampleImage?: {
		src: string;
		alt: string;
	};
};

export function DoDont(props: DoDontProps): JSX.Element {
	const { variant = "doThis", exampleImage } = props;

	const children =
		React.Children.count(props.children) === 1 && typeof props.children === "string" ? (
			<Paragraph>{props.children}</Paragraph>
		) : (
			props.children
		);

	return (
		<div className={cn(scss.container, scss[variant])}>
			{exampleImage && (
				<div className={cn(scss.exampleImage, scss[variant])}>
					<img src={exampleImage.src} alt={exampleImage.alt} />
				</div>
			)}
			<div className={scss.guideline}>
				<div className={scss.iconAndLabel}>
					<Icon fill={variant === "doThis" ? ColorStatusPositiveDark : ColorStatusNegativeDark}>
						{variant === "doThis" ? <IconSuccess /> : <IconError />}
					</Icon>
					<InlineText
						emphasis="strong"
						size="medium"
						tone={variant === "doThis" ? "positive" : "negative"}
					>
						{variant === "doThis" ? "Do" : "Don't"}
					</InlineText>
				</div>
				<div className={scss.content}>
					<TextContainer article>{children}</TextContainer>
				</div>
			</div>
		</div>
	);
}

export interface DoDontLayoutProps {
	children: React.ReactNode;
}

function DoDontLayout({ children }: DoDontLayoutProps): JSX.Element {
	return <div className={scss.layout}>{children}</div>;
}

DoDont.Layout = DoDontLayout;

type DoDontPropsWithoutVariant = Omit<DoDontProps, "variant">;
DoDont.Do = (props: DoDontPropsWithoutVariant) => <DoDont {...props} variant="doThis" />;
DoDont.Dont = (props: DoDontPropsWithoutVariant) => <DoDont {...props} variant="dontDoThis" />;
